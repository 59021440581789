import React, { Component } from "react";
import MenuTile from "./common/MenuTile";
import Axios from "axios";
import moment from "moment";
import Loader from "react-loader";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import DateRangePickerDropdown from "./DateRangePickerDropdown";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import { currencyFormat } from "./common/commonFns";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

class AgentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      r1: false, //r = http Request, to check response and same for following.
      r2: false,
      r3: false,
      r4: false,
      r5: false,
      r6: false,
      r7: false,
      r8: false,
      isLoaded: true,
      notPaidCommissionsData: [],
      hidden: "hidden",
      feesPaidEnrolments: [],
      agentId: localStorage.getItem("agentId"),
      invoiceDetailsData: [],
      communicationsCount: "",
      enrolmentsCommission: [],
      DateRnBkgrd: "hidden",
      tilesData: [
        {
          label: "Pending Applications",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clipRule="evenodd"
              ></path>
            </svg>
          ),
          link: "/dashboard/PendingApplications",
          data: "",
        },
        {
          label: "Overdue Fees",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              ></path>
            </svg>
          ),
          link: "/dashboard/overDues",
        },
        {
          label: "Communications",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clipRule="evenodd"
              ></path>
            </svg>
          ),
          link: "/dashboard/communications",
        },
        {
          label: "Documents",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
              <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
            </svg>
          ),
          link: "/dashboard/documents",
          viewBox: "0 0 24 24",
        },
        {
          label: "Active Enrolments",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path>
            </svg>
          ),
          link: "/dashboard/ActiveEnrolments",
          viewBox: "0 0 24 24",
        },
        {
          label: "Commissions Due",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
              <path
                fillRule="evenodd"
                d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                clipRule="evenodd"
              ></path>
            </svg>
          ),
          link: "/dashboard/commissionDue",
          viewBox: "0 0 24 24",
        },
        {
          label: "Future Commissions",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
              <path
                fillRule="evenodd"
                d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                clipRule="evenodd"
              ></path>
            </svg>
          ),
          link: "/dashboard/FutureCommission",
          viewBox: "0 0 24 24",
        },
        {
          label: "Paid Commissions",
          value: "",
          description: "Loading...",
          svg: (
            <svg
              className="w-6 h-6 fill-current text-gray-500"
              fill="gray"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
              <path
                fillRule="evenodd"
                d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                clipRule="evenodd"
              ></path>
            </svg>
          ),
          link: "/dashboard/paidCommissionsView",
          viewBox: "0 0 24 24",
        },
      ],
      t1StartDate: moment().subtract(6, "month").toDate(),
      t1EndDate: new Date(),
      t2StartDate: moment().subtract(6, "month").toDate(),
      t2EndDate: new Date(),
      t3StartDate: moment().subtract(6, "month").toDate(),
      t3EndDate: new Date(),
      t4StartDate: moment().subtract(6, "month").toDate(),
      t4EndDate: new Date(),
      t5StartDate: moment().subtract(6, "month").toDate(),
      t5EndDate: new Date(),
      t6StartDate: moment().subtract(6, "month").toDate(),
      t6EndDate: new Date(),
      t7StartDate: moment().subtract(6, "month").toDate(),
      t7EndDate: new Date(),
      t8StartDate: moment().subtract(6, "month").toDate(),
      t8EndDate: new Date(),
      dateRangeLabel1: localStorage.getItem("dateRangeLabel1"),
      dateRangeLabel2: localStorage.getItem("dateRangeLabel2"),
      dateRangeLabel3: localStorage.getItem("dateRangeLabel3"),
      dateRangeLabel4: localStorage.getItem("dateRangeLabel4"),
      dateRangeLabel5: localStorage.getItem("dateRangeLabel5"),
      dateRangeLabel6: localStorage.getItem("dateRangeLabel6"),
      dateRangeLabel7: localStorage.getItem("dateRangeLabel7"),
      dateRangeLabel8: localStorage.getItem("dateRangeLabel8"),
    };
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("firstRowIndex", 0);
    localStorage.setItem("lastRowIndex", 9);

    this.setState({ hidden: "", isLoaded: false });

    let t1StartDate = localStorage.getItem("t1StartDate");
    let t1EndDate = localStorage.getItem("t1EndDate");
    let t2StartDate = localStorage.getItem("t2StartDate");
    let t2EndDate = localStorage.getItem("t2EndDate");
    let t3StartDate = localStorage.getItem("t3StartDate");
    let t3EndDate = localStorage.getItem("t3EndDate");
    let t4StartDate = localStorage.getItem("t4StartDate");
    let t4EndDate = localStorage.getItem("t4EndDate");
    let t5StartDate = localStorage.getItem("t5StartDate");
    let t5EndDate = localStorage.getItem("t5EndDate");
    let t6StartDate = localStorage.getItem("t6StartDate");
    let t6EndDate = localStorage.getItem("t6EndDate");
    let t7StartDate = localStorage.getItem("t7StartDate");
    let t7EndDate = localStorage.getItem("t7EndDate");
    let t8StartDate = localStorage.getItem("t8StartDate");
    let t8EndDate = localStorage.getItem("t8EndDate");

    if (t1StartDate && t1EndDate) {
      this.setState(
        { t1StartDate: t1StartDate, t1EndDate: t1EndDate },
        function () {
          this.pendingPFn();
        }
      );
    } else {
      this.pendingPFn();
    }
    if (t2StartDate && t2EndDate) {
      this.setState(
        { t2StartDate: t2StartDate, t2EndDate: t2EndDate },
        function () {
          this.overDuesFn();
        }
      );
    } else {
      this.overDuesFn();
    }
    if (t3StartDate && t3EndDate) {
      this.setState(
        { t3StartDate: t3StartDate, t3EndDate: t3EndDate },
        function () {
          this.communicationsCountFn();
        }
      );
    } else {
      this.communicationsCountFn();
    }
    if (t4StartDate && t4EndDate) {
      this.setState(
        { t4StartDate: t4StartDate, t4EndDate: t4EndDate },
        function () {
          this.documentsCountFn();
        }
      );
    } else {
      this.documentsCountFn();
    }
    if (t5StartDate && t5EndDate) {
      this.setState(
        { t5StartDate: t5StartDate, t5EndDate: t5EndDate },
        function () {
          this.getEnrolmentsCommission();
        }
      );
    } else {
      this.getEnrolmentsCommission();
    }
    if (t6StartDate && t6EndDate) {
      this.setState(
        { t6StartDate: t6StartDate, t6EndDate: t6EndDate },
        function () {
          this.paidEnrolmentsFn();
        }
      );
    } else {
      this.paidEnrolmentsFn();
    }
    if (t7StartDate && t7EndDate) {
      this.setState(
        { t7StartDate: t7StartDate, t7EndDate: t7EndDate },
        function () {
          this.notPaidCommissionsFn();
        }
      );
    } else {
      this.notPaidCommissionsFn();
    }
    if (t8StartDate && t8EndDate) {
      this.setState(
        { t8StartDate: t8StartDate, t8EndDate: t8EndDate },
        function () {
          this.paymentRecordFn();
        }
      );
    } else {
      this.paymentRecordFn();
    }

    this.futureCommissionFn();
  }

  checkAllResponse() {
    if (
      this.state.r1 &&
      this.state.r2 &&
      this.state.r3 &&
      this.state.r4 &&
      this.state.r5 &&
      this.state.r6 &&
      this.state.r7 &&
      this.state.r8
    ) {
      this.setState({ hidden: "hidden", isLoaded: true });
    }
  }

  dateRangeString(startDate, endDate, tileName) {
    let labelStr = "";
    let sDate = moment(startDate).format("DD-MMM-YYYY");
    let eDate = moment(endDate).format("DD-MMM-YYYY");

    if (tileName === "tile1") {
      labelStr = this.state.dateRangeLabel1;
    } else if (tileName === "tile2") {
      labelStr = this.state.dateRangeLabel2;
    } else if (tileName === "tile3") {
      labelStr = this.state.dateRangeLabel3;
    } else if (tileName === "tile4") {
      labelStr = this.state.dateRangeLabel4;
    } else if (tileName === "tile5") {
      labelStr = this.state.dateRangeLabel5;
    } else if (tileName === "tile6") {
      labelStr = this.state.dateRangeLabel6;
    } else if (tileName === "tile7") {
      labelStr = this.state.dateRangeLabel7;
    } else if (tileName === "tile8") {
      labelStr = this.state.dateRangeLabel8;
    }
    if (labelStr === "Custom Range") {
      // let dateStr
      return sDate.toString() + " to " + eDate.toString();
    } else {
      return labelStr;
    }
  }
  handleEvent(e, p, event) {
    // console.log(e + " " + p + " " + event);
    if (event) {
      this.setState({ DateRnBkgrd: "" });
    } else {
      this.setState({ DateRnBkgrd: "hidden" });
    }
  }
  tileDateRangeBtn(opens, drops, startDate, endDate, tileName) {
    return (
      <div className="flex   p-1">
        <div className=" ">
          <p className="text-xxs text-gray-600 pt-1">
            {this.dateRangeString(startDate, endDate, tileName)}
          </p>
        </div>

        <div className="ml-2 flex justify-center">
          <DateRangePickerDropdown
            startDate={startDate}
            endDate={endDate}
            opens={opens}
            drops={drops}
            onShow={(event, picker) => {
              this.handleEvent(event, picker, true);
            }}
            onHide={(event, picker) => {
              this.handleEvent(event, picker, false);
            }}
            handleCallback={(start, end, label) =>
              this.handleCallback(start, end, label, tileName)
            }
          />
        </div>
      </div>
    );
  }

  handleCallback(start, end, label, tileName) {
    if (tileName === "tile1") {
      localStorage.setItem("dateRangeLabel1", label);
      this.setState(
        { t1StartDate: start, t1EndDate: end, dateRangeLabel1: label },
        function () {
          this.pendingPFn();
        }
      );
    } else if (tileName === "tile2") {
      localStorage.setItem("dateRangeLabel2", label);
      this.setState(
        { t2StartDate: start, t2EndDate: end, dateRangeLabel2: label },
        function () {
          this.overDuesFn();
        }
      );
    } else if (tileName === "tile3") {
      localStorage.setItem("dateRangeLabel3", label);
      this.setState(
        { t3StartDate: start, t3EndDate: end, dateRangeLabel3: label },
        function () {
          this.communicationsCountFn();
        }
      );
    } else if (tileName === "tile4") {
      localStorage.setItem("dateRangeLabel4", label);
      this.setState(
        { t4StartDate: start, t4EndDate: end, dateRangeLabel4: label },
        function () {
          this.documentsCountFn();
        }
      );
    } else if (tileName === "tile5") {
      localStorage.setItem("dateRangeLabel5", label);
      this.setState(
        { t5StartDate: start, t5EndDate: end, dateRangeLabel5: label },
        function () {
          this.getEnrolmentsCommission();
        }
      );
    } else if (tileName === "tile6") {
      localStorage.setItem("dateRangeLabel6", label);
      this.setState(
        { t6StartDate: start, t6EndDate: end, dateRangeLabel6: label },
        function () {
          this.paidEnrolmentsFn();
        }
      );
    } else if (tileName === "tile7") {
      localStorage.setItem("dateRangeLabel7", label);
      this.setState(
        { t7StartDate: start, t7EndDate: end, dateRangeLabel7: label },
        function () {
          this.notPaidCommissionsFn();
        }
      );
    } else if (tileName === "tile8") {
      localStorage.setItem("dateRangeLabel8", label);
      this.setState(
        { t8StartDate: start, t8EndDate: end, dateRangeLabel8: label },
        function () {
          this.paymentRecordFn();
        }
      );
    }
    //this.setState({ DateRnBkgrd: "hidden" });
  }

  //Tile 1
  // following function will make a https request for pending Proposals Data
  async pendingPFn() {
    let tData = this.state.tilesData;
    tData[0].value = "0";
    tData[0].description = "Loading....";
    this.setState({ tilesData: tData });

    const dateFormatter = new DateFormatter();
    let startDate = dateFormatter.formatterTwo(this.state.t1StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t1EndDate);
    localStorage.setItem("t1StartDate", startDate);
    localStorage.setItem("t1EndDate", endDate);

    agentsRequests.pendingProposalRequest(startDate, endDate).then((result) => {
      let ppData = result;

      if (ppData !== "error") {
        let count = ppData.length;
        let totalComission = 0;
        ppData.map((object, index) => {
          totalComission = totalComission + parseFloat(object.commission);
        });

        tData[0].dateRangeBtn = this.tileDateRangeBtn(
          "right",
          "down",
          this.state.t1StartDate,
          this.state.t1EndDate,
          "tile1"
        );
        tData[0].startDate = startDate;
        tData[0].endDate = endDate;
        tData[0].data = ppData;
        tData[0].label = "Pending Applications";
        tData[0].value = count;
        tData[0].description =
          "Projected commissions: $" + currencyFormat(totalComission);
        this.setState({ tilesData: tData, r1: true }, function () {
          this.checkAllResponse();
        });
        tData[0].extraEvent = this.newApplication;
      }
    });
  }

  //Tile 2
  // following function will make a http request for student overdues Data
  async overDuesFn() {
    let startDate = dateFormatter.formatterTwo(this.state.t2StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t2EndDate);
    localStorage.setItem("t2StartDate", startDate);
    localStorage.setItem("t2EndDate", endDate);

    let tData = this.state.tilesData;
    tData[1].value = "0";
    tData[1].description = "Loading....";
    this.setState({ tilesData: tData });

    agentsRequests.overDueInvoices(startDate, endDate).then((result) => {
      let overDuesData = result;
      let totalOverDueAmt = 0;

      overDuesData.map((object) => {
        totalOverDueAmt = totalOverDueAmt + parseFloat(object.balanceAmt);
      });

      let tData = this.state.tilesData;
      tData[1].startDate = startDate;
      tData[1].endDate = endDate;
      tData[1].data = overDuesData;
      tData[1].label = "Overdue Invoices";
      tData[1].value = overDuesData.length;
      tData[1].description =
        "Overdue Amount: $" + currencyFormat(totalOverDueAmt);
      tData[1].dateRangeBtn = this.tileDateRangeBtn(
        "right",
        "down",
        this.state.t2StartDate,
        this.state.t2EndDate,
        "tile2"
      );
      this.setState({ tilesData: tData, r2: true }, function () {
        this.checkAllResponse();
      });
    });
  }

  //Tile 3
  async communicationsCountFn() {
    let tData = this.state.tilesData;
    tData[2].value = "0";
    tData[2].description = "Loading....";
    this.setState({ tilesData: tData });

    let startDate = dateFormatter.formatterTwo(this.state.t3StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t3EndDate);
    localStorage.setItem("t3StartDate", startDate);
    localStorage.setItem("t3EndDate", endDate);

    Axios.get(
      constants.BASEURL +
        "Agents/CommunicationsCount/" +
        this.state.agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        let commCount = response.data;
        let cCount = commCount[0].totalCommunicationsCount;
        let tData = this.state.tilesData;
        tData[2].value = cCount;
        tData[2].description = "Till " + moment(endDate).format("DD-MMM-YYYY");
        tData[2].dateRangeBtn = this.tileDateRangeBtn(
          "left",
          "down",
          this.state.t3StartDate,
          this.state.t3EndDate,
          "tile3"
        );
        this.setState({ tilesData: tData, r3: true }, function () {
          this.checkAllResponse();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Tile 4
  async documentsCountFn() {
    let tData = this.state.tilesData;
    tData[3].value = "0";
    tData[3].description = "Loading....";
    this.setState({ tilesData: tData });
    let startDate = dateFormatter.formatterTwo(this.state.t4StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t4EndDate);
    localStorage.setItem("t4StartDate", startDate);
    localStorage.setItem("t4EndDate", endDate);

    Axios.get(
      constants.BASEURL +
        "Agents/DocumentsCount/" +
        this.state.agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        let docCount = response.data;
        //console.log("IDetailsss " + JSON.stringify(iDetailsData));
        let dCount = docCount[0].totalDocumentsCount;
        let tData = this.state.tilesData;

        tData[3].dateRangeBtn = this.tileDateRangeBtn(
          "left",
          "down",
          this.state.t4StartDate,
          this.state.t4EndDate,
          "tile4"
        );

        tData[3].value = dCount;
        tData[3].description = "Till " + moment(endDate).format("DD-MMM-YYYY");
        this.setState({ tilesData: tData, r4: true }, function () {
          this.checkAllResponse();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Tile 5
  allActiveEnrolmentsFn(startDate, endDate) {
    let tData = this.state.tilesData;
    tData[4].value = "0";
    tData[4].description = "Loading....";
    this.setState({ tilesData: tData });

    agentsRequests.allActiveEnrolments(startDate, endDate).then((response) => {
      let allEnrolments = response;

      if (allEnrolments !== "error") {
        let enrolmentsCommission = this.state.enrolmentsCommission;
        let structure = {
          enrolmentID: "",
          commission: "",
          invoiceAvailable: "",
        };
        let filteredData = [];
        if (enrolmentsCommission) {
          allEnrolments.map((object) => {
            let a = enrolmentsCommission.find(
              (element) => element.enrolmentID == object.enrolmentID
            );

            if (a) {
              structure.enrolmentID = a.enrolmentID;
              structure.commission = a.totalCommission;
              filteredData.push({ ...structure });
            } else {
              structure.enrolmentID = object.enrolmentID;
              structure.commission = 0;
              filteredData.push({ ...structure });
            }
          });
        }

        let eCount = allEnrolments.length;
        let totalCommission = 0;
        let tData = this.state.tilesData;
        tData[4].dateRangeBtn = this.tileDateRangeBtn(
          "right",
          "up",
          this.state.t5StartDate,
          this.state.t5EndDate,
          "tile5"
        );
        filteredData.map((object) => {
          totalCommission = totalCommission + parseFloat(object.commission);
        });
        tData[4].value = eCount;
        tData[4].description =
          "Total Commission: $" + currencyFormat(totalCommission);
        this.setState({ tilesData: tData, r5: true }, function () {
          this.checkAllResponse();
        });
      }
    });
  }

  //Tile 6
  commissionDueFn(startDate, endDate) {
    let tData = this.state.tilesData;
    tData[5].value = "0";
    tData[5].description = "Loading....";
    this.setState({ tilesData: tData });

    agentsRequests.agentCommissionDue(startDate, endDate).then((response) => {
      let notPaidCommissionsData = response;
      let feesPaidEnrolments = this.state.feesPaidEnrolments;
      let totalCommission = 0;
      let structure = {
        enrolmentID: "",
        commission: "",
      };
      let filteredData = [];
      if (notPaidCommissionsData != "error") {
        notPaidCommissionsData.map((object) => {
          let a = feesPaidEnrolments.find(
            (element) => element.enrolmentID == object.enrolmentID
          );

          if (a) {
            totalCommission = totalCommission + object.totalCommission;
            structure.enrolmentID = a.enrolmentID;
            structure.commission = object.totalCommission;
            filteredData.push({ ...structure });
          }
        });
      }
      let tData = this.state.tilesData;
      tData[5].dateRangeBtn = this.tileDateRangeBtn(
        "right",
        "up",
        this.state.t6StartDate,
        this.state.t6EndDate,
        "tile6"
      );
      let value = parseFloat(totalCommission).toFixed(2);
      tData[5].value = filteredData.length;

      tData[5].description =
        "Amount: $" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.setState(
        {
          tilesData: tData,
          r6: true,
        },
        function () {
          this.checkAllResponse();
        }
      );
    });
  }

  //Tile 7
  async futureCommissionFn() {
    let notPaidCommissionsData = this.state.notPaidCommissionsData;
    let tData = this.state.tilesData;
    let totalCommission = 0;
    tData[6].dateRangeBtn = this.tileDateRangeBtn(
      "left",
      "up",
      this.state.t7StartDate,
      this.state.t7EndDate,
      "tile7"
    );

    notPaidCommissionsData.map((object) => {
      totalCommission = totalCommission + object.totalCommission;
    });
    tData[6].value = notPaidCommissionsData.length;
    let value = parseFloat(totalCommission).toFixed(2);
    tData[6].description =
      "Amount: $" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ tilesData: tData, r7: true }, function () {
      this.checkAllResponse();
    });
  }

  //Tile 8
  async paymentRecordFn() {
    let tData = this.state.tilesData;
    tData[7].value = "0";
    tData[7].description = "Loading....";
    this.setState({ tilesData: tData });

    let startDate = dateFormatter.formatterTwo(this.state.t8StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t8EndDate);
    localStorage.setItem("t8StartDate", startDate);
    localStorage.setItem("t8EndDate", endDate);

    agentsRequests.paymentRecord(startDate, endDate).then((result) => {
      let allPayments = result;
      if (result != "error") {
        let totalPayment = 0;
        let tData = this.state.tilesData;
        allPayments.map((object) => {
          totalPayment = totalPayment + parseFloat(object.paidAmount);
        });

        tData[7].value = allPayments.length;
        tData[7].description = "Amount Paid: $" + currencyFormat(totalPayment);
        tData[7].dateRangeBtn = this.tileDateRangeBtn(
          "left",
          "up",
          this.state.t8StartDate,
          this.state.t8EndDate,
          "tile8"
        );

        this.setState({ tilesData: tData, r8: true }, function () {
          this.checkAllResponse();
        });
      }
    });
  }

  async paidEnrolmentsFn() {
    const dateFormatter = new DateFormatter();
    let startDate = dateFormatter.formatterTwo(this.state.t6StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t6EndDate);
    localStorage.setItem("t6StartDate", startDate);
    localStorage.setItem("t6EndDate", endDate);

    agentsRequests.feesPaidEnrolments(startDate, endDate).then((response) => {
      let ennrolments = response;
      this.setState(
        {
          feesPaidEnrolments: ennrolments,
        },
        function () {
          this.commissionDueFn(startDate, endDate);
        }
      );
    });
  }

  async getEnrolmentsCommission() {
    let startDate = dateFormatter.formatterTwo(this.state.t5StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t5EndDate);
    localStorage.setItem("t5StartDate", startDate);
    localStorage.setItem("t5EndDate", endDate);
    agentsRequests.enrolmentCommission(startDate, endDate).then((result) => {
      let enrolmentsCommissionData = result;
      if (enrolmentsCommissionData !== "error") {
        this.setState(
          {
            enrolmentsCommission: enrolmentsCommissionData,
          },
          function () {
            this.allActiveEnrolmentsFn(startDate, endDate);
          }
        );
      }
    });
  }
  newApplication() {
    return "/webForm/"+localStorage.getItem("defaultWebForm");
  }

  async notPaidCommissionsFn() {
    let startDate = dateFormatter.formatterTwo(this.state.t7StartDate);
    let endDate = dateFormatter.formatterTwo(this.state.t7EndDate);
    localStorage.setItem("t7StartDate", startDate);
    localStorage.setItem("t7EndDate", endDate);

    agentsRequests.agentCommissionDue(startDate, endDate).then((response) => {
      let notPaidCommissionsData = response;
      this.setState(
        {
          notPaidCommissionsData: notPaidCommissionsData,
        },
        function () {
          this.futureCommissionFn(startDate);
        }
      );
    });
  }
  stopLoader() {
    this.setState({ hidden: "hidden", isLoaded: true });
  }

  renderTile(tile) {
    let tData = this.state.tilesData;
    return (
      <MenuTile
        label={tData[tile].label}
        value={tData[tile].value}
        description={tData[tile].description}
        viewBox={tData[tile].viewBox}
        svg={tData[tile].svg}
        link={tData[tile].link}
        dateRangeBtn={tData[tile].dateRangeBtn}
        extraEvent={tData[tile].extraEvent}
      />
    );
  }

  render() {
    const { isLoaded } = this.state;
    return (
      <div className="lg:mt-10">
        <span
          className={
            this.state.hidden +
            "fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-50"
          }
        />
        <Loader id="l1" loaded={isLoaded}></Loader>

        <div className="my-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          {this.renderTile(0)}
          {/* {this.renderTile(1)} */}
          {this.renderTile(2)}
          {this.renderTile(3)}
          {this.renderTile(4)}
          {/* {this.renderTile(5)}
          {this.renderTile(6)}
          {this.renderTile(7)} */}
        </div>
        <span
          onClick={() => {
            this.handleEvent(false);
          }}
          className={
            this.state.DateRnBkgrd +
            " z-30 fixed top-0 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
          }
        />
      </div>
    );
  }
}

export default AgentDashboard;
