import React, { Component } from "react";
import Avatar from "./Avatar";
import logo from "../../assets/images/clientlogo.png";
import Transition from "./Transition.jsx";
import { Link } from "react-router-dom";

class NavBar extends Component {
  state = {
    isOpen: false,
    hidden: false,
    aName: localStorage.getItem("agentName"),
  };
  handleClick() {
    const isOpen = this.state.isOpen;
    if (isOpen === true) {
      this.setState({ isOpen: false });
      this.setState({ hidden: false });
    } else if (isOpen === false) {
      this.setState({ isOpen: true });
      this.setState({ hidden: true });
    }
  }
  optionSelection(option) {
    if (option === "request") {
      this.setState({ isOpen: false });
      this.setState({ hidden: false });
    }
  }
  render() {
    return (
      <nav className="z-30 relative bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link
                  to="/dashboard"
                  onClick={() => {
                    this.optionSelection("request");
                  }}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100"
                >
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={logo}
                    alt="Workflow logo"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={logo}
                    alt="Workflow logo"
                  />
                </Link>
              </div>
            </div>
            <div className="flex"><div className="flex-shrink-0 flex items-center text-xl font-bold">{localStorage.getItem("agentName")}</div></div>            
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <button title="logout"
                className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
                aria-label="Notifications"
              >
                <Link
                  to="/Login"
                  onClick={() => {
                    this.optionSelection("request");
                  }}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100"
                >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
              </Link>

                {/* <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg> */}
              </button>

              {/* Profile dropdown */}
              <div className="ml-3 relative">
                {/* <div>
                  <button
                    className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out hover:border-gray-300"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    // onMouseOver={() => {
                    //   this.handleClick();
                    // }}
                    onClick={() => {
                      this.handleClick();
                    }}
                  >
                    <Avatar />
                  </button>
                </div> */}
                <span
                  onClick={() => {
                    this.optionSelection("request");
                  }}
                  className={
                    (!this.state.hidden ? "hidden" : "") +
                    "fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-black opacity-50"
                  }
                />
                {/* <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
                <Transition
                  show={this.state.isOpen}
                  enter="transition ease-out duration-200 transform"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-200 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-50"
                >
                  <div
                    id="dropDown"
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <div className="py-2 pl-2 text-left bg-gray-100 sm:border-b sm:border-double sm:border-dashed">
                      <div className="text-base font-medium leading-6 text-gray-800">
                        {this.state.aName}
                      </div>
                      <div className="text-sm font-medium leading-5 text-gray-500">
                        {/* tom@example.com */}
                      </div>
                    </div>
                    <Link
                      to="/dashboard"
                      onClick={() => {
                        this.optionSelection("request");
                      }}
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                    >
                      Edit Profile
                    </Link>

                    <Link
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      onClick={() => {
                        this.optionSelection("request");
                      }}
                      to={{
                        pathname: "/dashboard/viewContracts",
                        // state: { fromDashboard: true },
                      }}
                    >
                      View Contracts
                    </Link>
                    <div className={"space-y-6 sm:space-y-5 "}>
                      <div className="sm:border-t sm:border-dashed sm:border-gray-200 ">
                        <Link
                          to="/"
                          onClick={() => {
                            this.optionSelection("request");
                          }}
                          className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                        >
                          Sign out
                        </Link>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <button
                onClick={() => {
                  this.handleClick();
                }}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                {/* Menu open: "hidden", Menu closed: "block" */}

                <svg
                  className="block h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* Menu open: "block", Menu closed: "hidden" */}
                <svg
                  className="hidden h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* <!--
      Mobile menu, toggle classes based on menu state.

      Open: "block", closed: "hidden"
    --> */}
        <Transition
          show={this.state.isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="block sm:hidden">
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0"></div>
                <Avatar />
                <div className="ml-3">
                  <div className="text-base font-medium leading-6 text-gray-800">
                    {this.state.aName}
                  </div>
                  <div className="text-sm font-medium leading-5 text-gray-500">
                    {/* tom@example.com */}
                  </div>
                </div>
              </div>
              <div
                className="mt-3"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <Link
                  to="/dashboard"
                  onClick={() => {
                    this.optionSelection("request");
                  }}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                >
                  Edit Profile
                </Link>

                <Link
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                  onClick={() => {
                    this.optionSelection("request");
                  }}
                  to={{
                    pathname: "/dashboard/viewContracts",
                    // state: { fromDashboard: true },
                  }}
                >
                  View Contracts
                </Link>

                <Link
                  to="/"
                  onClick={() => {
                    this.optionSelection("request");
                  }}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                >
                  Sign out
                </Link>
              </div>
            </div>
          </div>
        </Transition>
      </nav>
    );
  }
}

export default NavBar;
